import React, { useEffect, useRef, useState } from 'react'
import './LocationAll.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';
import Google from '../../Images/Google.png'
import book from '../../Images/book.png'
import telephone from '../../Images/telephone.png'
import LocationData from './LocationData'
import Review from '../../Images/Review.png'
import locationpin from '../../Images/locationpin.png'
import HomeAdvisor from '../../Images/HomeAdvisor.png'
import BBB from '../../Images/BBB.png'
import Angie from '../../Images/Angie.png'
import YELP from '../../Images/YELP.png'
import Trust from '../../Images/Trust.png'
import Porch from '../../Images/Porch.png'

//
// import GoogleMapReact from "google-map-react";

export default function LocationAll() {
    const mapRef = useRef(null);
    const navigate = useNavigate()
    // 
    const [markers, setMarkers] = useState([]);
    const [latLongArrat, setLatLongArrat] = useState([
        {
            "position": {
                "lat": 33.8365932,
                "lng": -117.9143012
            },
            "title": "Anaheim"
        },
        {
            "position": {
                "lat": 34.1539731,
                "lng": -118.6473801
            },
            "title": "Calabasas"
        },
        {
            "position": {
                "lat": 33.8752935,
                "lng": -117.5664384
            },
            "title": "Corona"
        },
        {
            "position": {
                "lat": 34.0922335,
                "lng": -117.435048
            },
            "title": "Long Beach"
        },
        {
            "position": {
                "lat": 34.1425078,
                "lng": -118.255075
            },
            "title": "Glendale"
        },
        {
            "position": {
                "lat": 33.7700504,
                "lng": -118.1937395
            },
            "title": "Long Beach"
        },
        {
            "position": {
                "lat": 33.9424658,
                "lng": -117.2296717
            },
            "title": "Moreno Valley"
        },
        {
            "position": {
                "lat": 34.1975048,
                "lng": -119.1770516
            },
            "title": "Oxnard"
        },
        {
            "position": {
                "lat": 34.1477849,
                "lng": -118.1445155
            },
            "title": "Pasadena"
        },
        {
            "position": {
                "lat": 34.055103,
                "lng": -117.7499909
            },
            "title": "Pomona"
        },
        {
            "position": {
                "lat": 34.10639889999999,
                "lng": -117.5931084
            },
            "title": "Rancho Cucamonga"
        },
        {
            "position": {
                "lat": 34.0555693,
                "lng": -117.1825381
            },
            "title": "Redlands"
        },
        {
            "position": {
                "lat": 34.1064001,
                "lng": -117.3703235
            },
            "title": "Rialto"
        },
        {
            "position": {
                "lat": 33.9806005,
                "lng": -117.3754942
            },
            "title": "Long Beach"
        },
        {
            "position": {
                "lat": 34.1083449,
                "lng": -117.2897652
            },
            "title": "San Bernardino"
        },
        {
            "position": {
                "lat": 33.7454725,
                "lng": -117.867653
            },
            "title": "Santa Ana"
        },
        {
            "position": {
                "lat": 33.4934243,
                "lng": -117.1488156
            },
            "title": "Temecula"
        },
        {
            "position": {
                "lat": 34.1761133,
                "lng": -118.8487793
            },
            "title": "Thousand Oaks"
        },
        {
            "position": {
                "lat": 33.8358492,
                "lng": -118.3406288
            },
            "title": "Torrance"
        },
        {
            "position": {
                "lat": 34.2804923,
                "lng": -119.2945199
            },
            "title": "Ventura"
        }
    ]);
    // 
    const handleApiLoaded = ({ map, maps, location }) => {
        mapRef.current = map;
        new maps.Marker({
            "position": {
                "lat": 33.9806005,
                "lng": -117.3754942
            },
            "title": "Long Beach",
            "map": map,
        });
        // latLongArrat.map((A, index) => {
        //     new maps.Marker({
        //         position: A.position,
        //         map: map,
        //         title: A.title
        //     });
        // })
        setMarkers([...markers]);
    };

    // useEffect(async () => {
    //     const prelatLongArrat = []
    //     for (let index = 0; index < LocationData.length; index++) {
    //         const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${LocationData[index].City},ca&key=AIzaSyAqdgsErGbUqnP1mYicv7QLBFpGiF6osMY&language=en`, { method: 'GET', })
    //         const data = await res.json()
    //         console.log(data);
    //         prelatLongArrat.push({
    //             position: data.results[0].geometry.location,
    //             // map: map,
    //             title: LocationData[index].City
    //         })
    //     }
    //     console.log(prelatLongArrat);
    // console.log(latLongArrat);
    // }, []);

    return (
        <div className="LocationAll">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Long Beach Chimney Sweep | Our Chimney Sweep Locations</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Long Beach Chimney Sweep | Our Locations" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Long Beach Chimney Sweep | Our Locations" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.chimneysweeplongbeach.org.org/locations" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Discover Our Locations At Long Beach Chimney Sweep Serving | Chimney Sweep Repair in Long Beach, CA area and surrounding cities." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Discover Our Locations At Long Beach Chimney Sweep Serving | Chimney Sweep Repair in Long Beach, CA area and surrounding cities." data-react-helmet="true" />
                <meta name="keywords" content="Chimney Sweeping, Chimney Repair, Chimney Sweep, Chimney Sweep inspection, Chimney Sweep repair, Chimney Sweep maintenance, Creosote removal, Chimney Sweep safety, Chimney Sweep flue Repair, Soot removal, Chimney Sweep Maintenance Locations" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Long Beach Chimney Sweep" data-react-helmet="true" />
            </Helmet>
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>OUR LOCATIONS</h1>
                    <h2>Welcome to Long Beach Chimney Sweep Service Provider</h2>
                    <span>
                        <a href="tel:562-534-9071">CALL 562-534-9071<img src={telephone} alt="" /></a>
                        <a href="/book">BOOK AN APPOINTMENT<img src={book} alt="" /></a>
                    </span>
                    <span className="GoogleButt">
                        <h6>5 Stars - Based on 3,618 Reviews</h6>
                        <h6 className="GoogleButtBooms">
                            Within 5 mi • Within 20 mi • Open now • Residential • Top rated • Prices • Online estimates • Replacement
                        </h6>
                    </span>
                </div>
                <div className="BookAnAppointmentMainComponentEmergery">
                    <div className="BookAnAppointmentMainComponentRates">
                        <div className="BookAnAppointmentMainComponentRatesImages">
                            <img src={YELP} alt="Long Beach Air Duct Cleaning Yelp Review" />
                            <img src={Angie} alt="Long Beach Air Duct Cleaning Angie Review" />
                            <img src={HomeAdvisor} alt="Long Beach Air Duct Cleaning HomeAdvisor Review" />
                            <img src={BBB} alt="Long Beach Air Duct Cleaning BBB Review" />
                            <img src={Google} alt="Long Beach Air Duct Cleaning Google Review" />
                            <img src={Porch} alt="Long Beach Air Duct Cleaning Porch Review" />
                            <img src={Trust} alt="Long Beach Air Duct Cleaning Trust Review" />
                        </div>
                        <h1>Customer Testimonials</h1>
                        <h2>⭐⭐⭐⭐⭐</h2>
                        <h3>
                            I’ve used Long Beach Chimney Sweep for both cleaning and repairs, and I’m always impressed with their level of service. They use top-quality materials and are very detail-oriented. My chimney has never been in better shape, and I appreciate their commitment to safety and customer satisfaction.
                            <a href={`/reviews/${LocationData.find(a => a.City == ("Long Beach").replace("%20", '')).City.replace(" ", '').toLowerCase()}`}>MORE FROM OUR CUSTOMERS &gt;</a>
                        </h3>

                    </div>
                    <div className="BookAnAppointmentMainComponentEmergeryBox">
                        <a href="tel:562-534-9071">For emergency service call: 562-534-9071</a>
                    </div>
                </div>

            </div>

            <div className="LocationAllListMap">
                <div className="LocationAllLsit">
                    <div className="LocationAllBox1 LocationAllBox">
                        <div className="LocationAllBoxLeft">
                            <h2>Long Beach, CA</h2>
                            <h3>Open Now 24/7 • Top Rated • Same Day Service</h3>
                            <h4>Serving Chimney Sweep Repair in Long Beach, CA area and surrounding cities.</h4>
                            <h5><a href='/'>VISIT THIS LOCATION</a> - <a href={`/services`}>SEE SERVICES</a></h5>

                            <span>
                                <a href={`tel:562-534-9071`}>
                                    <img src={[telephone]} alt={`562-534-9071 - Long Beach Chimney Sweep`} />562-534-9071
                                </a>
                                <a href={`/book`}>
                                    <img src={[book]} alt={`SCHEDULE ONLINE Long Beach Chimney Sweep`} />SCHEDULE ONLINE
                                </a>
                                <a href={`/reviews/${LocationData.find(a => a.City == ("Long Beach").replace("%20", '')).City.replace(" ", '').toLowerCase()}`} >
                                    <img src={[locationpin]} alt={`Read reviews of Long Beach Chimney Sweep`} />READ REVIEWS
                                </a>
                            </span>
                        </div>
                        <div className="LocationAllBoxRight">
                            <div className="Mapsss">
                                {/* <GoogleMapReact
                                    bootstrapURLKeys={{ key: 'AIzaSyAqdgsErGbUqnP1mYicv7QLBFpGiF6osMY' }}
                                    defaultCenter={latLongArrat ? latLongArrat.find(a => a.title == "Long Beach").position : null}
                                    defaultZoom={10}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps, location }) => {
                                        mapRef.current = map;
                                        new maps.Marker({
                                            "position": latLongArrat ? latLongArrat.find(a => a.title == "Long Beach").position : null,
                                            "title": latLongArrat ? latLongArrat.find(a => a.title == "Long Beach").title : null,
                                            "map": map,
                                        });
                                        setMarkers([...markers]);
                                    }}

                                >
                                </GoogleMapReact> */}
                            </div>
                        </div>
                    </div>
                    {latLongArrat && latLongArrat.length ? LocationData.map((A, index) =>
                        <div key={index} className="Header1BoxMenuBox">
                            <div className="LocationAllBox">
                                <div className="LocationAllBoxLeft">
                                    <h2>{A.City}, {A.State}</h2>
                                    <h3>Open Now 24/7 • Top Rated • Same Day Service</h3>
                                    <h4>Serving Chimney Sweep in {A.City}, CA area and surrounding cities.</h4>
                                    <h5><a href={A.Url} target='_blank'>VISIT THIS LOCATION</a> - <a href={`${A.Url}/services`} target='_blank'>SEE SERVICES</a></h5>
                                    <span>
                                        <a href={`tel:${A.Phone}`}>
                                            <img src={[telephone]} alt={`${A.Phone} - ${A.City} Chimney Sweep`} />{A.Phone}
                                        </a>

                                        <a href={`${A.Url}/book`}>
                                            <img src={[book]} alt={`SCHEDULE ONLINE ${A.City} Chimney Sweep`} />SCHEDULE ONLINE
                                        </a>

                                        <a href={`/reviews/${A.City.replace(' ', '').toLowerCase()}`} >
                                            <img src={[locationpin]} alt={`Read reviews of ${A.City} Chimney Sweep`} />READ REVIEWS
                                        </a>
                                    </span>
                                </div>
                                <div className="LocationAllBoxRight">
                                    <div className="Mapsss">
                                        {/* <GoogleMapReact
                                            bootstrapURLKeys={{ key: 'AIzaSyAqdgsErGbUqnP1mYicv7QLBFpGiF6osMY' }}
                                            defaultCenter={latLongArrat ? latLongArrat[index].position : null}
                                            defaultZoom={10}
                                            yesIWantToUseGoogleMapApiInternals
                                            onGoogleApiLoaded={({ map, maps, location }) => {
                                                mapRef.current = map;
                                                new maps.Marker({
                                                    "position": latLongArrat ? latLongArrat[index].position : null,
                                                    "title": latLongArrat ? latLongArrat[index].title : null,
                                                    "map": map,
                                                });
                                                setMarkers([...markers]);
                                            }}
                                        >
                                        </GoogleMapReact> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>

            </div>

            <NavigatorPath />

        </div >
    )
}

